.list-main {
    text-align: center;
    /* position: relative;
    display: block; */
}
.list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    /* overflow-y: scroll; */
}
.video-list-bar {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 80%;
    margin: 10px auto;
    flex-wrap: wrap;
}
/* .hover:hover {
    color: blueviolet;
    font-size: 25pt;
} */