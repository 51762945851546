.inputWrapper {
    display: flex;
    margin: auto;
    margin-top: 20px;
    max-width: 500px;
}

.tableWrapper {
    max-width: 700px;
    margin: 20px auto;
}

.input {
    display: flex;
    margin: auto;
    margin-top: 20px;
    max-width: 500px;
}