.dropzone {
    text-align: center;
    padding: 10px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    max-width: 700px;
}

.dropzone:hover {
    border: 3px solid #eeeeee;
}
