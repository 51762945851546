
.requiredField::before {
    content: "*";
    color: red;
}

.helperText {
    font-size: 0.85em;
    color: red;
    margin-bottom: 10px;
}

.buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
