.card {
    -webkit-box-shadow: 0px 0px 15px 7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 15px 7px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 15px 7px rgba(34, 60, 80, 0.2);
    width: 300px;
    min-height: 300px;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    background-color: black;
}
.card-bar {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    color: rgb(89, 194, 255);
    cursor: pointer;
}