.container {
    /* overflow-y: scroll; */
    margin: 0 auto;
    padding: 0 50px;
    max-width: 1600px;
}

.scrollingContainer {
    overflow-y: scroll;
}
