.navWrapper2 {
  margin: auto;
  width: 100%;
  max-width: 1920px;
}
.navWrapper {
  display: flex;
  justify-content: space-between;
}
.navTabsWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.userInfoWrapper {
  display: flex;
  /* display: flex; */
  /* justify-content: space-between; */
}
/* 
// app: {
  //     height: '100%',
  //     display: "flex",
  //     flexDirection: "column",
  // },
  // flexContainer: {
  //     maxWidth: 1920,
  //     margin: "0 auto",
  // },
  // root: {
  //     minHeight: navTabHeight,
  //     color: "#a4a4a5",
  //     background: 'transparent',
  // },
  // navWrapper2: {
  //     // background: 'rgb(166,166,166)',
  //     background: 'linear-gradient(90deg, rgba(166,166,166,1) 0%, rgba(255,255,255,1) 32%)',
  // },
  // navWrapper: {
  //     display: 'flex',
  //     justifyContent: 'space-between',
  // },
  // logo: {
  //     alignSelf: "center",
  //     padding: "6px 6px 6px 0",
  //     minWidth: 102,
  //     opacity: 1,
  //     cursor: "unset",
  // },
  // logout: {
  //     color: "#a9a9a9",
  //     minHeight: navTabHeight,
  //     height: navTabHeight,
  //     padding: "0px 10px",
  //     fontFamily: "AcuminConcept, sans-serif",
  //     textTransform: "unset",
  //     "&:hover": {
  //         color: "grey"
  //     },
  // }, */