.user-list-main {
    text-align: center;
    /* background-color: rgb(235, 235, 235); */
    /* width: 80%; */
    /* margin: 20px auto;
    padding: 10px 15px; */
    /* -webkit-box-shadow: 0px 0px 15px -3px #000000;  */
    /* box-shadow: 0px 0px 15px -3px #000000; */
}

.users-list-bar {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 80%;
    margin: 10px auto;
    flex-wrap: wrap;
}

.tableWrapper {
    padding: 0 30px;
    max-width: 80%;
    margin: 20px auto;
}

/* If the screen size is XXXpx or less */
@media only screen and (max-width: 1200px) {
    .tableWrapper {
        max-width: 100%;
    }
}